import React from "react";
import styled from "styled-components";

import img1 from "../../assets/greate_ux.png";
import img2 from "../../assets/secure.png";
import img3 from "../../assets/simple.png";
import img4 from "../../assets/value.png";
import { FormattedMessage } from "react-intl";
import { Context } from "../Wrapper";
import { useIntl } from "react-intl";

const Section = styled.section`
  /* min-height: 100vh; */
  /* min-height: 110vh; */
  width: 100vw;

    /* background-color: ${(props) => props.theme.body}; */
  position: relative;

  direction: ${(props) => props.direction};
  display: flex;
  flex-direction: column;
  align-items: center;

`;

const Title = styled.h1`
  /* font-size: ${(props) => props.theme.fontxxl}; */
  text-transform: capitalize;
  font-size: x-large;
  /* color: ${(props) => props.theme.text}; */
  /* align-self: flex-start; */
  /* display: flex; */
  /* justify-content: right; */
  /* align-items: left; */
  text-align: center;
  /* margin: 1rem auto; */
  /* border-bottom: 2px solid ${(props) => props.theme.text}; */
  /* width: fit-content; */
  /* width: 80%; */
  /* color: #002856; */
  width: ${(props) => props.theme.width};
  padding-top: 20px;
  padding-bottom: 20px;




  @media (max-width: ${(props) => props.theme.width}) {
    font-size: xx-large;
    width: 90%;
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.text};
  /* margin: 1rem auto; */
  font-weight: 400;
  /* align-items: center; */
  /* align-self: center; */
  /* align-self: flex-start; */
  /* text-align: center; */



  @media (max-width: ${(props) => props.theme.width}) {
    width: 90%;
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const Container = styled.div`
  width: 85%;
  margin: 2rem auto;
  width: 1160px;

  display: flex;
  /* justify-content: space-between; */
  justify-content: space-between;
  /* align-content: center; */
  flex-wrap: wrap;
  gap: 24px;
  @media (max-width: 64em) {
    width: 85%;
  }
  @media (max-width: 48em) {
    width: 90%;
    justify-content: center;
  }
  margin-bottom: 8em;
`;

const Item = styled.div`
  width: calc(20rem - 4vw);
  /* padding: 24px 24px; */
  padding-top: 24px;
  color: ${(props) => props.theme.body};
  /* margin: 1rem 2rem; */
  position: relative;

  /* border: 2px solid ${(props) => props.theme.text}; */
  border-radius: 20px;
  transition: all 0.3s ease;
  flex-grow: 1;

  &:hover {
    img {
      transform: translateY(-2rem) scale(1.2);
    }
  }

  @media (max-width: 30em) {
    width: 70vw;
  }
`;

const ImageContainer = styled.div`
  /* width: 60%; */
  /* margin: 0 auto; */
  /* background-color: ${(props) => props.theme.carouselColor}; */
  /* background-color: #f5f5f5; */

  /* border: 1px solid ${(props) => props.theme.text}; */
  /* padding: 1rem; */

  /* border-radius: 20px; */
  /* cursor: pointer; */

  /* img {
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
  } */
`;

const Name = styled.h3`
  /* font-size: ${(props) => props.theme.fontsm}; */
  display: flex;
  /* align-items: center; */
  /* justify-content: left; */
  /* text-transform: uppercase; */
  color: ${(props) => props.theme.text};
  margin-top: 1rem;
  /* text-align: -webkit-center; */
  min-height: 40px;
  justify-content: center;
`;

const Position = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: rgba(${(props) => props.theme.textRgba}, 0.9);
  font-weight: 400;
  padding-top: 1rem;
  justify-content: center;
  text-align: -webkit-center;
  line-height: normal;
`;

const Icon = styled.i`
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;  /* Preferred icon size */
  /* display: inline-block; */
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ${(props) => props.direction};
  vertical-align: top;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  /* font-feature-settings: 'liga'; */
  /* margin-right: 10px;
  margin-left: 10px; */

  display: flex;
    flex-direction: column;
    align-items: center;


`;

const MemberComponent = ({ img, name = " ", position = " " }) => {
  return (
    <Item>
      {img}



      <Name>{name}</Name>
      <Position>{position}</Position>
    </Item>
  );
};

const Product1Features = () => {
  const context = React.useContext(Context);

  const intl = useIntl();
  // const title1 = intl.formatMessage({ id: "app.features.greateFinEx" });
  const title1 = "Improved Efficiency";
  // const details1 = intl.formatMessage({
  //   id: "app.features.greateFinExDetails",
  // });
  const details1 = "Streamlined communication and processing save time, eliminating visits to banks or beneficaires.";



  // const title2 = intl.formatMessage({ id: "app.features.extreamSec" });
  const title2 = "Reduce Errors";
  // const details2 = intl.formatMessage({
  //   id: "app.features.extreamSecDetails",
  // });
  const details2 = "Automatic issuance, with agreed texts, and less manual labor radically reduce mistakes.";

  const title3 = intl.formatMessage({ id: "app.features.extreamSimple" });
  const details3 = intl.formatMessage({
    id: "app.features.extreamSimpleDetails",
  });

  // const title4 = intl.formatMessage({ id: "app.features.value" });
  const title4 = "Better Oversight";
  // const details4 = intl.formatMessage({
  //   id: "app.features.valueDetails",
  // });
  const details4 = "A centralized digital system provides clear management and tracking.";

  return (
    <Section
      id="team"
      direction={context.locale.startsWith("ar") ? "rtl" : "ltr"}
    >
      {/* <Title>"Your data integration will be accelerated by 10X, which reflects to the operation cost"</Title> */}

      <Container>
        <MemberComponent img={<Icon><svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#0693E3"><path d="M102.67-240v-480l350.66 240-350.66 240Zm404.66 0v-480L858-480 507.33-240Zm-338-240ZM574-480ZM169.33-366.67l166-113.33-166-113.33v226.66Zm404.67 0L740-480 574-593.33v226.66Z" /></svg></Icon>} name={title1} position={details1} />
        <MemberComponent img={<Icon><svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#0693E3"><path d="m778-268-48-47.33L773.33-358v-244L602-773.33H358l-43.33 42.66L268-778l62-62h300l210 210v299.33L778-268ZM513.33-532v-153.33h-66.66v86.66L513.33-532Zm316 503.33L684-174l-54 54H330L120-330.67V-630l54-53.33-145.33-146L75.33-876 876-75.33l-46.67 46.66Zm-306.66-494ZM358-186.67h244l34.67-34-416-416-34 34.67v244L358-186.67Zm122-94q-15 0-25.83-10.83-10.84-10.83-10.84-25.83 0-15 10.84-25.84Q465-354 480-354q15 0 25.83 10.83 10.84 10.84 10.84 25.84t-10.84 25.83Q495-280.67 480-280.67Zm-51.33-148Z" /></svg></Icon>} name={title2} position={details2} />
        <MemberComponent img={<Icon><svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#0693E3"><path d="M480.08-326.67q72.25 0 122.75-50.58 50.5-50.57 50.5-122.83 0-72.25-50.58-122.75-50.57-50.5-122.83-50.5-72.25 0-122.75 50.58-50.5 50.57-50.5 122.83 0 72.25 50.58 122.75 50.57 50.5 122.83 50.5Zm-.24-62.66q-46.17 0-78.34-32.33-32.17-32.32-32.17-78.5 0-46.17 32.33-78.34 32.32-32.17 78.5-32.17 46.17 0 78.34 32.33 32.17 32.32 32.17 78.5 0 46.17-32.33 78.34-32.32 32.17-78.5 32.17ZM480-200q-146 0-264.67-82.5Q96.67-365 40-500q56.67-135 175.33-217.5Q334-800 480-800t264.67 82.5Q863.33-635 920-500q-56.67 135-175.33 217.5Q626-200 480-200Zm0-300Zm-.11 233.33q118.44 0 217.61-63.5 99.17-63.5 151.17-169.83-52-106.33-151.06-169.83-99.05-63.5-217.5-63.5-118.44 0-217.61 63.5-99.17 63.5-151.83 169.83 52.66 106.33 151.72 169.83 99.05 63.5 217.5 63.5Z" /></svg></Icon>} name={title4} position={details4} />

        {/* <MemberComponent img={img4} name={title4} position={details4} /> */}
      </Container>
    </Section>
  );
};

export default Product1Features;
