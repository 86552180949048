import styled, { ThemeProvider } from "styled-components";
import GlobalStyle from "./styles/GlobalStyles";
import { light } from "./styles/Themes";
import Navigation from "./components/Navigation";
import Home from "./components/sections/Home";

import About from "./components/sections/About.js";
import Footer from "./components/Footer";
import React, { Fragment, useEffect, useState } from "react";
import ScrollToTop from "./components/ScrollToTop";
import Usecases from "./components/sections/Usecases";
import Features from "./components/sections/Features";
import Mission from "./components/sections/Mission";

import UsecasesExtenstion from "./components/sections/UsecasesExtenstion.";
import Recognition from "./components/sections/Recognition";
import ForFIInfo from "./components/sections/ForFIInfo";
import Service1TypeWriterText from "./components/service_sections/Service1TypeWriterText.js";
import Service1Home from "./components/service_sections/Service1Home.js";
import Service1Pitch from "./components/service_sections/Service1Pitch.js";
import Service1Features from "./components/service_sections/Service1Features.js";
import Service1Statement from "./components/service_sections/Service1Statement.js";
import SeeIt from "./components/sections/SeeIt.js";
import Product1Home from "./components/product_sections/Product1Home.js";
import Product1Pitch from "./components/product_sections/Product1Pitch.js";
import Product1HowItWorks from "./components/product_sections/Product1HowItWorks.js";
import Product1Features from "./components/product_sections/Product1Features.js";
import Product1Statement from "./components/product_sections/Product1Statement.js";
import ProductFinanceAppHome from "./components/product_financeApp_sections/ProductFinanceAppHome.js";
import ProductFinanceAppFeatures from "./components/product_financeApp_sections/ProductFinanceAppFeatures.js";
import ProductFinanceAppPitch from "./components/product_financeApp_sections/ProductFinanceAppPitch.js";
import ProductFinanceAppStatement from "./components/product_financeApp_sections/ProductFinanceAppStatement.js";
import Facebook from "./Icons/Facebook.js";
import ProductFinanceAppFaq from "./components/product_financeApp_sections/ProductFinanceAppFaq.js";
import axios from "axios";
import useId from "react-use-uuid";
import { Context } from "./components/Wrapper";


const Section = styled.section`
  /* min-height: calc(
    100vh -
      (
        ${(props) => props.theme.navHeight} +
          ${(props) => props.theme.footerHeight}
      )
  ); */
  /* width: 100vw; */
  position: relative;
  /* height: 400px; */
  background-color: ${(props) => props.theme.body}; 
`;




function ProductFinanceApp() {
  const [scrollDone, setScrollDone] = useState(false);


	const [userUUID, setUserUUID] = React.useState(() => {
    // getting stored value
    const saved = localStorage.getItem("userUUID");
    const initialValue = JSON.parse(saved);
    return initialValue || "";
  });


  const [y, setY] = useState(window.scrollY);
  
  
  
  useEffect(() => {

    if (userUUID === "") {
      //if found in localstorage load it
      ///setUserUUID(userUUIDVaIe);
      //} else {
      //if not found save
  
      setUserUUID(id);
      localStorage.setItem("userUUID", JSON.stringify(id));
    }

    
    window.addEventListener("scroll", (e) => handleScroll(e));
  
    return () => { // return a cleanup function to unregister our function since it will run multiple times
      window.removeEventListener("scroll", (e) => handleScroll(e));
    };

    setScrollDone(false);
  }, [y,userUUID,scrollDone]);


  function handleScroll(event) {
    // console.log('User scrolled!', event);
    // console.log(scrollDone);

    // //to call the scroll post event one time only (not working!!)
    // if (scrollDone === false){
    //   setScrollDone(true);

    //     //console.log(userUUID);
    //     if (userUUID !== "") {
    //       sendTrigger(userUUID);
    //     }
    //     else
    //       sendTrigger("userUUID");

    //   }
  }






  let axiosEnabled = true;
  const id = useId();
  const context = React.useContext(Context);

  const sendTrigger = async () => {
    //=========== semding trigger
    let doc = {
      document: {
        userId: userUUID,
        type: "Finance-LandingPage-Scrolled",
        local: context.locale,
      },
    };

    //setIsLoading(true);
    try {
      let { data } = "";

      if (axiosEnabled === true) {
        data = await axios.post(
          "https://landingpage-triggers.azurewebsites.net/api/landingpage-triggers?code=dDJ-cf4giE8k9C0HSuxsgT4Q4mgux1XMVwcOJ2Y-MdHRAzFuSTTdjQ%3D%3D",
          doc,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
      }

      //console.log("reponse data is: ", JSON.stringify(data, null, 4));

      //setData(data);
    } catch (err) {
      //setErr(err.message);
      //console.log("error message is: ", JSON.stringify(err.message, null, 4));
    } finally {
      //setIsLoading(false);
    }
    //===========
  };


  return (
    <div >
    <Section >
      <ProductFinanceAppHome/>
      <ProductFinanceAppFeatures />
      <ProductFinanceAppPitch />
      <ProductFinanceAppStatement/>
      <ProductFinanceAppFaq/>
      {/* 
      <Product1HowItWorks />
      <Product1Statement /> */}
      {/* <SeeIt /> */}

    </Section>
    </div>
  );
}

export default ProductFinanceApp;
