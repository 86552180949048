import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "./Button";
import Logo from "./Logo";
import { Link, matchRoutes, useLocation, useParams } from "react-router-dom";

import { debounce } from "./utilities/helpers";
import { FormattedMessage } from "react-intl";
import { Context } from "./Wrapper";
import BackGroundImage from "../assets/banner2.png";
import BackGroundImage_ar from "../assets/banner2_ar.png";
import $ from "jquery"
import guaranteeApp from "../assets/guaranteeApp.svg";
import guaranteeLink from "../assets/guaranteeLink.svg";
import obConnector from "../assets/obConnector.svg";
import financeApp from "../assets/financeApp.svg";


const Section = styled.header`
  width: 100vw;
  /* background-color: ${(props) => props.theme.body}; */
  background-color: white;
  

  border-bottom: 1px solid #D3D3D3;

  /* to v centerlaize the components and  */
  /* font-size: ${(props) => props.theme.fontmd}; */
  font-size: medium;

  /* font-weight: 500; */
  color: #303035;

  /* position: sticky; */
  /* top: 0; */
  z-index: 999;
  height: ${(props) => props.theme.navHeight};
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15); */
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  // To hid the nav bar
  /* top: ${(props) => (props.visible ? "0" : "-100px")}; */
  /* transition: top 0.6s; */
  top: 0;


  /* direction: ${(props) => (props.direction == "rtl" ? "rtl" : "ltr")}; */
  /* direction: ${(props) => props.direction}; */

  /* background: url("http://jsequeiros.com/sites/default/files/imagen-cachorro-comprimir.jpg")
    no-repeat center center; */

  /* background: url(${BackGroundImage}) no-repeat center center; */
  /* direction: ${(props) => (props.direction == "rtl" ? "rtl" : "ltr")}; */

  background-size: cover;
  font-family: "IBM Plex Sans Arabic", sans-serif;

  /*
    opacity: 0.5;
     top: 0;
  left: 0;
  bottom: 0;
  right: 0; 
  position: absolute;
  z-index: -1;
    -webkit-filter: grayscale(1); 
  filter: grayscale(1);*/

  /* font-family: ${(props) =>
    props.direction === "rtl" ? "HelveticaNeueLT Arabic 55 Roman" : "Poppins"}; */

    @media (max-width: ${(props) => props.theme.width}) {
    .desktop {
      display: none;
    }
    .mobile {
      display: inline-block;
      overflow: auto;
      width: auto;

      /* justify-content: flex-start; */
    }
  }
`;

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;

  //width: 85%;
  width: ${(props) => props.theme.width};
  height: ${(props) => props.theme.navHeight};
  margin: 0 auto;

  height: 100%;
  font-family: "IBM Plex Sans Arabic", sans-serif;

  

  .mobile {
    display: none;
  }

  @media (max-width: ${(props) => props.theme.width}) {
    .desktop {
      display: none;
    }
    .mobile {
      /* width: 100%; */
      display: inline-block;
      /* justify-content: flex-start; */
    }
  }


`;

const Menu = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  width: 100%;
  height: 100%;

  direction: ${(props) => props.direction};
  font-family: "IBM Plex Sans Arabic", sans-serif;


  @media (max-width: ${(props) => props.theme.width}) {
    /* 1024 px */
    position: fixed;
    top: ${(props) => props.theme.navHeight};
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: calc(100vh - ${(props) => props.theme.navHeight});
    z-index: 50;
    background-color: rgba(${(props) => props.theme.bodyRgba}, 0.93);

    backdrop-filter: blur(2px);
    transform: ${(props) =>
    props.click ? "translateY(0) " : "translateY(1000%) "};

    transition: all 0.3s ease;
    flex-direction: column;
    justify-content: center;

    /* touch-action: none; */
    justify-self: self-end;
    overflow: auto;

    /* background-color: red; */
    justify-content: flex-start;
    


  }

  //this to make the menu on top , not in  center:
  /* @media (max-width: 64em) {
    justify-content: flex-start;
    top: auto;
  } */
`;
const Menu1 = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  width: 100%;
  height: 100%;

  direction: ${(props) => props.direction};
  font-family: "IBM Plex Sans Arabic", sans-serif;


  @media (max-width: ${(props) => props.theme.width}) {
    /* 1024 px */
    position: fixed;
    top: ${(props) => props.theme.navHeight};
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: calc(100vh - ${(props) => props.theme.navHeight});
    z-index: 50;
    background-color: rgba(${(props) => props.theme.bodyRgba}, 0.93);

    backdrop-filter: blur(2px);
    transform: ${(props) =>
    props.click ? "translateY(0) " : "translateY(1000%) "};

    transition: all 0.3s ease;
    flex-direction: column;
    justify-content: center;

    /* touch-action: none; */
    justify-self: self-end;
    overflow: auto;

    /* background-color: red; */

    align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: max-content;
  top: revert-layer;

  min-width: -webkit-fill-available;
        flex-direction: row;

  }

  //this to make the menu on top , not in  center:
  /* @media (max-width: 64em) {
    justify-content: flex-start;
    top: auto;
  } */
`;


const MenuItem = styled.li`
  margin: 0 1rem;
  color: ${(props) => props.theme.text};
  cursor: pointer;
  /* font-weight: 630; */
  /* font-size: ${(props) => props.theme.fontmd}; */
  font-size: large;
  height: 100%;
  align-content: center;
  font-size: large;
  font-weight: bold;
  font-family: "IBM Plex Sans Arabic", sans-serif;

  a {
    color: ${(props) => props.theme.text};

    &:hover {
      color:   ${(props) => props.theme.brandBlue};;
      font-weight: bold;
    }

  }



  /* &:hover::after  {
    width: 100%;
  } */
  @media (max-width: ${(props) => props.theme.width}) {
    margin: 1rem 0;
    height: auto;
    &::after {
      display: none;
    }
  }
`;

const DropButton = styled.button`
  font-size: large;

  padding: 0 1rem;
  color: ${(props) => props.theme.text};
  cursor: pointer;
  /* font-weight: bold; */
  background-color: inherit;
  border: none;
  outline: none;

  height: 100%;
  align-content: center;

  font-size: large;
  font-weight: bold;
  font-family: "IBM Plex Sans Arabic", sans-serif;

  
  :hover{
    color:   ${(props) => props.theme.brandBlue};
    font-weight: bold;
    ;
  }

 


  /* @media (max-width: 64em) {
    margin: 1rem 0;
    height: 100%;

    &::after {
      display: none;
    }
  } */
`;

const SubMenu = styled.div`
  ////// &&&& to show the submenu
  display: none;
  font-family: "IBM Plex Sans Arabic", sans-serif;

  position: absolute;
  background-color: ${(props) => props.theme.lightGray};;
  border-radius: 0 0 0px 15px;

  /* min-width: 100%; */
  width: 100vw;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: ${(props) => props.theme.navHeight};

  left: 0;
  
  a {
    float: none;
    /* color: black; */
    color: ${(props) => props.theme.text};

    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    /* font-weight: 450; */
    font-size: ${(props) => props.theme.fontsm};

    
  }
  

  a:hover {
    background-color: #ddd;

    
  }

  span {
    /* text-transform: uppercase; */
    /* font-family: "Akaya Telivigala", cursive; */
  }

  .text-1 {
    font-size: ${(props) => props.theme.fontxs};
    direction: ${(props) => props.direction};
    line-height: 1.6;
  }
  .text-2 {
    font-size: ${(props) => props.theme.fontsm};
    color: ${(props) => props.theme.brandBlue};;;
    /* font-weight: 450; */
  }

  @media (max-width: ${(props) => props.theme.width}) {
    top: auto;
    padding-bottom: 100px;
    width: 100%;
    border-radius: 0 50px 0px 15px;

    &::after {
      display: none;
    }
  }
`;

const SubMenuItem = styled.li`
  float: left;
  overflow: hidden;
  
  height: 100%;
  align-content: center;
  font-family: "IBM Plex Sans Arabic", sans-serif;


  &:hover ${SubMenu} {

 
    display: block;
  }

  &:hover ${SubMenu} {
    display: block;
  }
  @media (max-width: ${(props) => props.theme.width}) {
    height: 9%;
    &::after {
      display: none;
    }
  }

`;

const MenuItemNew = styled.li`
  /* margin-left: 8px; */
  color: ${(props) => props.theme.text};
  cursor: pointer;
  margin-left: auto;
  font-family: "IBM Plex Sans Arabic", sans-serif;

  &::after {
    content: " ";
    display: block;
    width: 0%;
    height: 4px;
    /* background: ${(props) => props.theme.text}; */
    /* background: ${(props) => props.theme.brandBlue};;; */

    transition: width 0.3s ease;
  }
  &:hover::after {
    width: 100%;
  }
  @media (max-width: ${(props) => props.theme.width}) {
    margin: 1rem 0;

    &::after {
      display: none;
    }
  }
`;

const HamburgerMenu = styled.span`
  width: ${(props) => (props.click ? "2rem" : "1.5rem")};
  height: 2px;
  background: ${(props) => props.theme.text};

  /* position: absolute; */
  top: 2rem;
  left: 50%;
  transform: ${(props) =>
    props.click
      ? "translateX(-50%) rotate(90deg)"
      : "translateX(-50%) rotate(0)"};

  display: none;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: all 0.3s ease;

  font-family: "IBM Plex Sans Arabic", sans-serif;

  @media (max-width: ${(props) => props.theme.width}) {
    /* 1024 px */
    display: flex;
  }

  &::after,
  &:before {
    content: " ";
    width: ${(props) => (props.click ? "1rem" : "1.5rem")};
    height: 2px;
    right: ${(props) => (props.click ? "-2px" : "0")};

    background: ${(props) => props.theme.text};
    position: absolute;
    transition: all 0.3s ease;
  }

  &::after {
    top: ${(props) => (props.click ? "0.3rem" : "0.5rem")};
    transform: ${(props) => (props.click ? "rotate(-40deg)" : "rotate(0)")};
  }

  &::before {
    bottom: ${(props) => (props.click ? "0.3rem" : "0.5rem")};
    transform: ${(props) => (props.click ? "rotate(40deg)" : "rotate(0)")};
  }
`;


//to remove the  focus on select element on change
var c = 0;
$("select").bind('click', function (event) {
  event.stopPropagation();
  if (c++ % 2 == 1) {
    console.log(c);
    $(this).blur();
  }
});

$('html').click(function () {
  if ($('select').is(':focus'))
    c = 1;
  else
    c = 0;
});



const MegaMenu = styled.div`
  margin: auto;
  width: ${(props) => props.theme.width}; 
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  // background: "red";
  box-sizing: border-box;

  display: ${(props) =>
    props.isOpen
      ? "flex"
      : "none"};

  

  @media (max-width: ${(props) => props.theme.width}) {
    display: flex;
    flex-direction: column;
    padding-bottom: 180px;
    margin-bottom: 50px;
    padding-top: 20px;
    align-items: baseline;
    width: 100%;
    /* &::after {
      display: none;
    } */
  } 
`;

const MegaMenuTitle = styled.div`
  /* margin: auto; */
  // background: "yellow",
  vertical-align: top;
  width: 250px;
  padding-right: 60px;

  border-right: ${(props) => (props.locale === 'ar' ? "0px" : "1px solid #D3D3D3")};
  border-left: ${(props) => (props.locale === 'ar' ? "1px solid #D3D3D3" : "0px")};

  height: 200px;
  font-family: "IBM Plex Sans Arabic", sans-serif;

  h2 {

    color: "black";
    font-family: "IBM Plex Sans Arabic Regular";
  }
  
  @media (max-width: ${(props) => props.theme.width}) {
    width: 250px;
    padding-bottom: 80px;
    padding-bottom: 10px;
    padding-top: 10px;

    border-right: 0px;
    border-left: 0px;
    
    height: 100%;
    margin-top: 15px;
    padding-left: 32px;
    &::after {
      display: none;
    }
  } 
`;


const MegaMenuList = styled.ul`
  padding-right: 30px;  
  padding-left: 30px;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
  /* width: 300px; */
  vertical-align: top;
  line-height: 24px;

  font-family: "IBM Plex Sans Arabic", sans-serif;

  a {
    display: flex;
    align-items: center;
    font-size: ${(props) => props.theme.fontsm};
    /* justify-content: space-between; */
  }

  a:hover {


    font-size: ${(props) => props.theme.fontsm};
    color: ${(props) => props.theme.brandBlue};;;
    background: white;
    border-radius: .5rem;
    transition: top 0.9s; 
    }
  a:hover::after  {
      width: 100%;
    }

    li {
      /* display: flex;
      align-items: center; */
    }
  
  @media (max-width: ${(props) => props.theme.width}) {
    margin-top: 10px;

    margin-left: 50px;
    margin-right: 50px;
    flex-direction: column;

    padding-left: 50px;


  } 
`;


const ListTitle = styled.div`
  /* text-decoration: underline; */
  color: ${(props) => props.theme.lightGrayText};;
  padding-bottom: 20px;
  font-size: large;

  font-family: "IBM Plex Sans Arabic", sans-serif;

`;


const ListItemTitle = styled.span`
  /* text-decoration: underline; */
  /* font-size: ${(props) => props.theme.fontmd}; */
  font-size: medium;

  text-align: ${(props) => (props.locale === 'ar' ? "-webkit-right" : "-webkit-left")};

  font-family: "IBM Plex Sans Arabic", sans-serif;

  

`;

const ListItemSubTitle = styled.span`
  /* text-decoration: underline; */
  font-size: small;


  text-align: ${(props) => (props.locale === 'ar' ? "-webkit-right" : "-webkit-left")};
  font-family: "IBM Plex Sans Arabic", sans-serif;

`;


const Icon = styled.i`
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;  /* Preferred icon size */
  /* display: inline-block; */
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ${(props) => props.direction};
  vertical-align: top;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  /* font-feature-settings: 'liga'; */
  margin-right: 10px;
  margin-left: 10px;



`;

const Icon2 = styled.i`
 font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;  /* Preferred icon size */
  /* display: inline-block; */
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ${(props) => props.direction};
  vertical-align: top;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  margin-left: 4px;
`;


const ProductItem = styled.div`
  /* text-decoration: underline; */
  display: flex;
  align-items: center;
  font-family: "IBM Plex Sans Arabic", sans-serif;

  text-align: ${(props) => (props.locale === 'ar' ? "-webkit-right" : "-webkit-left")};
`;

const ProductIcon = styled.div`
  /* text-decoration: underline; */
  padding: 10px;
    margin: 10px;
    background: #D3E1FB;
    border-radius: 5px;
`;



const ImgContainerFooter = styled.div`
  /* width: 28rem; */
  flex: 70%;
  /* margin-right: 16px; */
  /* background-color: ${(props) => props.theme.body}; */

  width: 3rem;

  display: flex;
  font-family: "IBM Plex Sans Arabic", sans-serif;

  padding: 10px;
    /* margin: 10px; */
    /* background: white;
    border-radius: 5px; */

  img {
    /* width: 75%; */
    /* height: 50px; */
    width: 3em;

  }
  
  @media (max-width: ${(props) => props.theme.width}) {
    /* margin-left: 16px;
    margin-right: 16px; */
    /* width: 12rem; */

    /* margin-bottom: 40px; */
  }
`;


const Navigation = (prop) => {
  const params = useParams();

  //console.log(params);

  const [click, setClick] = useState(false);

  // new:
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  // new:
  const [visible, setVisible] = useState(true);
  const [visibleMenu, setVisibleMenu] = useState(true);

  const scrollTo = (id) => {
    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });

    setClick(!click);
  };

  const handleScroll = debounce(() => {
    // find current scroll position

    const currentScrollPos = window.pageYOffset;
    // set state based on location info (explained in more detail below)

    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
      currentScrollPos < 10
    );
    // set state to new scroll position

    setPrevScrollPos(currentScrollPos);
  }, 100);


  const [isOpen, setOpen] = useState(false);
  const { pathname } = useLocation();


  // new useEffect:
  useEffect(() => {

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  // new useEffect:
  useEffect(() => {
    setOpen(false); // Close the navigation panel
  }, [pathname]);


  const context = React.useContext(Context);

  //If the current pathname is /lg_request/* I will get path /members/:id


  const routes = [{ path: "/lg_request/*" }, { path: "/finance/*" }]

  const location = useLocation();
  let route;
  console.log("-----");

  console.log(location);
  console.log(routes);

  if (location !== null)
    route = matchRoutes(routes, location);

  console.log("route");

  console.log(route)

  let found = false;
  if (route !== null)
    for (var i = 0; i < route.length; i++) {
      if (route[i].pathnameBase === "/lg_request") {
        console.log("found");
        found = true;
        break;
      }

      if (route[i].pathnameBase === "/finance") {
        console.log("found");
      }
    }


  if (found) {
    return null;
  } else


    return (
      <Section
        id="navigation"
        visible={visible}
      // direction={context.locale.startsWith("ar") ? "rtl" : "ltr"}
      /* backgroundimage={
        context.locale.startsWith("ar") ? { BackGroundImage_ar } : { BackGroundImage }
      } */
      >
        <NavBar>
          <Logo />
          <HamburgerMenu click={click} onClick={() => setClick(!click)}>
            &nbsp;
          </HamburgerMenu>

          <Menu click={click}
            direction={context.locale.startsWith("ar") ? "rtl" : "ltr"}>

            <MenuItemNew></MenuItemNew>

            {/* ##########  Services */}
            <SubMenuItem onMouseOver={() => setOpen(true)} >
              <DropButton onClick={() => setOpen(!isOpen)} >
                <FormattedMessage
                  id="app.navigation.services"
                  defaultMessage="services"
                /><Icon2>keyboard_arrow_down</Icon2>
              </DropButton>

              <SubMenu >
                <MegaMenu isOpen={isOpen}>
                  <MegaMenuTitle locale={context.locale}>
                    <h2><FormattedMessage
                      id="app.navigation.services"
                      defaultMessage="services"
                    />
                    </h2><span className="text-1">Learn how we can help you to digitally transofrm</span>
                  </MegaMenuTitle>



                  <MegaMenuList >
                    <ListTitle>DATA EXCHANGE</ListTitle>
                    <li><Link to={"/service1"} onClick={() => { setOpen(!isOpen); setClick(!click); }} ><ListItemTitle locale={context.locale}>Data Exchange Gateway</ListItemTitle> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>
                    <li><Link to={"/service2"} onClick={() => { setOpen(!isOpen); setClick(!click); }} ><ListItemTitle locale={context.locale}>Data Exchange Connector</ListItemTitle> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>
                    <li><Link to={"/service3"} onClick={() => { setOpen(!isOpen); setClick(!click); }} ><ListItemTitle locale={context.locale}>Data Exchange Framework</ListItemTitle>{context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>
                  </MegaMenuList>
                  <MegaMenuList>
                    <ListTitle>OPEN BANKING</ListTitle>
                    <li><Link to={"/service4"} onClick={() => { setOpen(!isOpen); setClick(!click); }} ><ListItemTitle locale={context.locale}>TPP Enablement</ListItemTitle> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link> </li>
                    <li><Link to={"/service5"} onClick={() => { setOpen(!isOpen); setClick(!click); }} ><ListItemTitle locale={context.locale}>Streamlining LG Issuance</ListItemTitle> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>
                    <li><Link to={"/service6"} onClick={() => { setOpen(!isOpen); setClick(!click); }} ><ListItemTitle locale={context.locale}>Streamlining LG Requests</ListItemTitle>  {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>
                    <li><Link to={"/service7"} onClick={() => { setOpen(!isOpen); setClick(!click); }} ><ListItemTitle locale={context.locale}>Automating Customer Onboarding</ListItemTitle>  {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>
                    <li><Link to={"/service8"} onClick={() => { setOpen(!isOpen); setClick(!click); }} ><ListItemTitle locale={context.locale}>Automating Financial Verification </ListItemTitle> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>
                    <li><Link to={"/service9"} onClick={() => { setOpen(!isOpen); setClick(!click); }} ><ListItemTitle locale={context.locale}>Automating Bank-Account Verification </ListItemTitle> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>
                    <li><Link to={"/service10"} onClick={() => { setOpen(!isOpen); setClick(!click); }} ><ListItemTitle locale={context.locale}>Finance Decision Acceleration</ListItemTitle> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>
                    <li><Link to={"/service11"} onClick={() => { setOpen(!isOpen); setClick(!click); }} ><ListItemTitle locale={context.locale}>Direct Payment</ListItemTitle> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>
                  </MegaMenuList>
                  <MegaMenuList>
                    <ListTitle>FINANCIAL SYSTEM</ListTitle>
                    <li><Link to={"/service12"} onClick={() => { setOpen(!isOpen); setClick(!click); }} ><ListItemTitle locale={context.locale}>Financial System Insights</ListItemTitle> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link> </li>
                    <li><Link to={"/service13"} onClick={() => { setOpen(!isOpen); setClick(!click); }} ><ListItemTitle locale={context.locale}>ATM Replenishment Management</ListItemTitle> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>
                  </MegaMenuList>
                </MegaMenu>
              </SubMenu>
            </SubMenuItem>


            {/* ##########  Products */}
            <SubMenuItem onMouseOver={() => setOpen(true)} >
              <DropButton onClick={() => setOpen(!isOpen)} >
                <FormattedMessage
                  id="app.navigation.products"
                  defaultMessage="products"
                /><Icon2>keyboard_arrow_down</Icon2>
              </DropButton>

              <SubMenu>
                <MegaMenu isOpen={isOpen}>
                  <MegaMenuTitle locale={context.locale}>
                    <h2><FormattedMessage
                      id="app.navigation.products"
                      defaultMessage="products"
                    /></h2>
                    <span className="text-1">Lean about our Cutting-edge products, SaaS and Platfroms</span>
                  </MegaMenuTitle>

                  <MegaMenuList>
                    {/* <ListTitle>DATA EXCHANGE</ListTitle> */}
                    <li><Link to={"/product1"} onClick={() => { setOpen(!isOpen); setClick(!click); }}><ProductItem locale={context.locale}><div>
                      <ImgContainerFooter> <img
                        src={guaranteeApp}
                        alt="Leapro Platform" />
                      </ImgContainerFooter>
                    </div><div>
                        <ListItemTitle locale={context.locale}>GuaranteeApp</ListItemTitle><div><ListItemSubTitle locale={context.locale}>Bank Gurantees Made Simple</ListItemSubTitle></div></div></ProductItem> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>




                    <li><Link to={"/product2"} onClick={() => { setOpen(!isOpen); setClick(!click); }}><ProductItem locale={context.locale}><div>
                      <ImgContainerFooter> <img
                        src={obConnector}
                        alt="Leapro Platform" />
                      </ImgContainerFooter>

                    </div><div><ListItemTitle locale={context.locale}>OB Connector</ListItemTitle><div><ListItemSubTitle locale={context.locale}>Accelerate SME Finance</ListItemSubTitle></div></div></ProductItem> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>
                  </MegaMenuList>
                  <MegaMenuList>
                    <li><Link to={"/product3"} onClick={() => { setOpen(!isOpen); setClick(!click); }}><ProductItem locale={context.locale}><div>
                      <ImgContainerFooter> <img
                        src={guaranteeLink}
                        alt="Leapro Platform" />
                      </ImgContainerFooter></div><div>
                        <ListItemTitle locale={context.locale}>GuaranteeLink</ListItemTitle><div><ListItemSubTitle locale={context.locale}>Become TPP easily</ListItemSubTitle></div></div></ProductItem> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>

                        <li><Link to={"/finance"} onClick={() => { setOpen(!isOpen); setClick(!click); }}><ProductItem locale={context.locale}><div>
                      <ImgContainerFooter> <img
                        src={financeApp}
                        alt="Leapro Platform" />
                      </ImgContainerFooter></div><div>
                        <ListItemTitle locale={context.locale}>FinanceApp</ListItemTitle><div><ListItemSubTitle locale={context.locale}>Get quick access to financed</ListItemSubTitle></div></div></ProductItem> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>

                  </MegaMenuList>
                </MegaMenu>
              </SubMenu>
            </SubMenuItem>

            {/* ##########  Company */}
            <SubMenuItem onMouseOver={() => setOpen(true)}>
              <DropButton onClick={() => setOpen(!isOpen)} >
                <FormattedMessage
                  id="app.navigation.company"
                  defaultMessage="company"
                />
                <Icon2>keyboard_arrow_down</Icon2>
              </DropButton>
              <SubMenu>
                <MegaMenu isOpen={isOpen}>
                  <MegaMenuTitle locale={context.locale}>
                    <h2><FormattedMessage
                      id="app.navigation.company"
                      defaultMessage="company"
                    /></h2>
                    <span className="text-1">About Leapro</span>
                  </MegaMenuTitle>
                  <MegaMenuList>
                    <li><Link to={"/about"} onClick={() => { setOpen(!isOpen); setClick(!click); }} ><ListItemTitle locale={context.locale}>About Leaopro</ListItemTitle> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>
                    <li><Link to={"/contact-us"} onClick={() => { setOpen(!isOpen); setClick(!click); }}  ><ListItemTitle locale={context.locale}>Joint Our Team</ListItemTitle> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>
                    <li><Link to={"/contact-us"} onClick={() => { setOpen(!isOpen); setClick(!click); }}  ><ListItemTitle locale={context.locale}>Contact Us</ListItemTitle> {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</Link></li>
                  </MegaMenuList>

                </MegaMenu>
              </SubMenu>
            </SubMenuItem>

            <MenuItem onClick={() => { setClick(!click); }}>
              <Link to={"/blog"}>
                <FormattedMessage
                  id="app.navigation.blog"
                  defaultMessage="blog"
                />
                
              </Link>
            </MenuItem>
            <MenuItemNew></MenuItemNew>

            {/* </div> */}

            {/* <div style={{ display:"inherit"}}> */}


          </Menu>



          <Menu1 click={click}>
            <MenuItemNew>

              <select value={context.locale} onChange={context.selectLanguage} style={{
                gridColumnGap: "1rem", backgroundColor: "#EAEDF6",
                fontFamily: "IBM Plex Sans Arabic Regular",
                fontSize: "medium",
                marginRight: "32px",
                border: 0,
                borderRight: "14px solid transparent",
                // This line disable the blue border
                boxShadow: 'none',
                outline: "0px none #fff !important"
                , borderRadius: ".5rem", alignItems: "center", padding: "8px 8px", mainselection: {
                  overflow: "hidden"
                  , "width": "350px"
                }, focus: {
                  background: "#fff", outline: "none", boxShadow: "none"
                }
              }}>


                <option value="en" >EN</option>
                {/* <option value="ar">العربية</option> */}


              </select>
            </MenuItemNew>
            {/* <a data-language="ar" lang="ar" href="https://www.tarabut.com/ar/">العربية</a> */}
            {/* <Link onClick={context.selectLanguage}>العربية</Link> */}
            <Link to={"/contact-us"} onClick={() => { setClick(!click); }}><Button text="Contact Us"></Button></Link>

          </Menu1>

        </NavBar>
      </Section>
    );
};

export default Navigation;
