import React from "react";
import styled from "styled-components";

import img1 from "../../assets/greate_ux.png";
import img2 from "../../assets/secure.png";
import img3 from "../../assets/simple.png";
import img4 from "../../assets/value.png";
import { FormattedMessage } from "react-intl";
import { Context } from "../Wrapper";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import Button from "../Button";

const Section = styled.section`
  /* min-height: 100vh; */
  /* min-height: 110vh; */
  width: 100vw;

    /* background-color: ${(props) => props.theme.body}; */
  position: relative;

  direction: ${(props) => props.direction};
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #EFF2FB; */
  margin-bottom: 82px;
  font-family: "IBM Plex Sans Arabic", sans-serif;

`;

const Title = styled.h1`
  /* font-size: ${(props) => props.theme.fontxxl}; */
  text-transform: capitalize;
  font-size: xx-large;
  /* color: ${(props) => props.theme.text}; */
  /* align-self: flex-start; */
  /* display: flex; */
  /* justify-content: right; */
  /* align-items: left; */
  text-align: center;
  /* margin: 1rem auto; */
  /* border-bottom: 2px solid ${(props) => props.theme.text}; */
  /* width: fit-content; */
  /* width: 80%; */
  /* color: #002856; */
  width: ${(props) => props.theme.width};
  /* padding-top: 20px; */
  padding-bottom: 65px;

  color: #1F366B;

  font-family: "IBM Plex Sans Arabic", sans-serif;


  @media (max-width: ${(props) => props.theme.width}) {
    font-size: xx-large;
    width: 90%;
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.text};
  /* margin: 1rem auto; */
  font-weight: 400;
  /* align-items: center; */
  /* align-self: center; */
  /* align-self: flex-start; */
  /* text-align: center; */
  width: 1160px;

  @media (max-width: ${(props) => props.theme.width}) {
    font-size: large;
    width: 90%;
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const Container = styled.div`
  width: 85%;
  /* margin: 2rem auto; */
  width: 1160px;

  display: flex;
  /* justify-content: space-between; */
  justify-content: space-between;
  /* align-content: center; */
  flex-wrap: wrap;

  gap: 24px;
  flex-direction: column;

  direction: rtl;

  @media (max-width: ${(props) => props.theme.width}) {
    width: 90%;
    justify-content: center;
  }
  margin-bottom: 20px;
`;

const Item = styled.div`
  /* width: calc(20rem - 4vw); */
  padding: 12px 12px;
  color: ${(props) => props.theme.body};
  /* margin: 1rem 2rem; */
  position: relative;

  /* border: 2px solid ${(props) => props.theme.text}; */
  border-radius: 20px;
  transition: all 0.3s ease;
  flex-grow: 1;

  &:hover {
    img {
      transform: translateY(-2rem) scale(1.2);
    }
  }

  @media (max-width: ${(props) => props.theme.width}) {
    /* width: 90%; */
    flex: 0 0 100%;// don't grow, don't shrink, stay fixed at 50% width
  }
`;

const ImageContainer = styled.span`
  width: 60%;
  /* margin: 0 auto; */
  /* background-color: ${(props) => props.theme.carouselColor}; */
  /* background-color: #f5f5f5; */

  /* border: 1px solid ${(props) => props.theme.text}; */
  padding-right: 1rem;

  border-radius: 20px;
  /* cursor: pointer; */

  img {
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
  }
`;


const Item2 = styled.li`
  /* width: calc(20rem - 4vw); */
  /* width: 20px; */
  padding: 24px 24px;
  color: ${(props) => props.theme.body};
  /* margin: 1rem 2rem; */
  position: relative;

  /* border: 2px solid ${(props) => props.theme.text}; */
  
  transition: all 0.3s ease;
  flex-grow: 1;

  background-color: ${(props) => props.theme.lightGray};
  /* margin: 16px; */
  border: 1px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;

  flex: 0 0 43%; /* don't grow, don't shrink, stay fixed at 50% width */
  scroll-snap-align: center;
  flex-shrink: 0;

  direction: rtl;
  font-family: "IBM Plex Sans Arabic", sans-serif;

  &:hover {
    img {
      transform: translateY(-2rem) scale(1.2);
    }
  }

  @media (max-width: 30em) {
    /* width: 90%; */
    flex: 0 0 80%;// don't grow, don't shrink, stay fixed at 50% width
      }
`;

const Name = styled.span`
  /* font-size: ${(props) => props.theme.fontsm}; */
  display: flex;
  /* align-items: center; */
  /* justify-content: left; */
  /* text-transform: uppercase; */
  color: ${(props) => props.theme.text};
  /* margin-top: 0.5rem; */
  /* margin-bottom: 0.5rem; */

  min-height: 30px;
  padding-right: 1rem;
  padding-left: 1rem;

  align-items: center;

  text-align: ${(props) => (props.locale === 'ar' ? "-webkit-right" : "-webkit-left")};
  font-size: 22px;
  font-family: "IBM Plex Sans Arabic", sans-serif;
  direction: rtl;
  text-align: right;


`;

const Position = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* text-transform: capitalize; */
  color: rgba(${(props) => props.theme.textRgba}, 0.9);
  font-weight: 400;
  padding-right: 1rem;
  /* margin-bottom: auto; */
  white-space: pre-line;
  line-height: 1.6;

  font-family: "IBM Plex Sans Arabic", sans-serif;

  direction: rtl;

`;

const Icon = styled.i`
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;  /* Preferred icon size */
  /* display: inline-block; */
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ${(props) => props.direction};
  vertical-align: top;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  /* font-feature-settings: 'liga'; */
  margin-right: 10px;
  margin-left: 10px;



`;

const ProductIcon = styled.div`
  /* text-decoration: underline; */
  padding: 10px;
    margin: 10px;
    background: #D3E1FB;
    border-radius: 5px;
    font-family: "IBM Plex Sans Arabic", sans-serif;

`;


const Name1 = styled.span`
  /* text-decoration: underline; */
  padding: 10px;
    margin: 10px;
    background: #D3E1FB;
    color: #5F6267;
    border-radius: 5px;
    font-size: medium;
    font-family: "IBM Plex Sans Arabic", sans-serif;

    direction: rtl;
    text-align: right;

`;


const MemberComponent = ({ id, icon, img, name = " ", name1="", position = " " }) => {

  const context = React.useContext(Context);

  return (
    <Item2 id={id}>
      <ImageContainer>
        {img}
      </ImageContainer>

      <Name><br></br>
        <div>{name}</div> &nbsp;&nbsp;<Name1>{name1}</Name1></Name>
      <Position>{position}</Position>
      <br></br>
      {/* <a href="/" style={{ color: "#009ad7" }}>Read more {context.locale === 'ar' ? <Icon style={{ "-webkit-transform": "scaleX(-1)", transform: "scaleX(-1)" }}>chevron_right</Icon> : <Icon>chevron_right</Icon>}</a> */}
    </Item2>
  );
};


const ProductFinanceAppPitch = () => {
  const context = React.useContext(Context);

  const intl = useIntl();
  const title1 = intl.formatMessage({ id: "app.features.greateFinEx" });
  const details1 = intl.formatMessage({
    id: "app.features.greateFinExDetails",
  });

  const title2 = intl.formatMessage({ id: "app.features.extreamSec" });
  const details2 = intl.formatMessage({
    id: "app.features.extreamSecDetails",
  });

  const title3 = intl.formatMessage({ id: "app.features.extreamSimple" });
  const details3 = intl.formatMessage({
    id: "app.features.extreamSimpleDetails",
  });

  const title4 = intl.formatMessage({ id: "app.features.value" });
  const details4 = intl.formatMessage({
    id: "app.features.valueDetails",
  });

  return (
    <Section
      id="team"
      direction={context.locale.startsWith("ar") ? "rtl" : "ltr"}
    >

<Title>كيف احصل على التمويل؟</Title>

      <Container>
        <MemberComponent
          name="قم بإنشاء حسابك" name1= "دقيقتان" 
          img={<svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#1F366B"><path d="M480.18-80q-82.83 0-155.67-31.5-72.84-31.5-127.18-85.83Q143-251.67 111.5-324.56T80-480.33q0-82.88 31.5-155.78Q143-709 197.33-763q54.34-54 127.23-85.5T480.33-880q82.88 0 155.78 31.5Q709-817 763-763t85.5 127Q880-563 880-480.18q0 82.83-31.5 155.67Q817-251.67 763-197.46q-54 54.21-127 85.84Q563-80 480.18-80Zm.15-66.67q139 0 236-97.33t97-236.33q0-139-96.87-236-96.88-97-236.46-97-138.67 0-236 96.87-97.33 96.88-97.33 236.46 0 138.67 97.33 236 97.33 97.33 236.33 97.33ZM480-480Zm-12 202h66.67v-404H386v66.67h82V-278Z"/></svg>}
          position={<><span>أخبرنا عن عملك وسنزودك بتقدير لمبلغ التمويل المحتمل.</span>
          </>} />
        <MemberComponent
          name="اتصل بمنصاتك بشكل آمن"  name1= "5 دقائق" 
          img={<svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#1F366B"><path d="M480.18-80q-82.83 0-155.67-31.5-72.84-31.5-127.18-85.83Q143-251.67 111.5-324.56T80-480.33q0-82.88 31.5-155.78Q143-709 197.33-763q54.34-54 127.23-85.5T480.33-880q82.88 0 155.78 31.5Q709-817 763-763t85.5 127Q880-563 880-480.18q0 82.83-31.5 155.67Q817-251.67 763-197.46q-54 54.21-127 85.84Q563-80 480.18-80Zm.15-66.67q139 0 236-97.33t97-236.33q0-139-96.87-236-96.88-97-236.46-97-138.67 0-236 96.87-97.33 96.88-97.33 236.46 0 138.67 97.33 236 97.33 97.33 236.33 97.33ZM480-480ZM363.33-278h233.34v-66.67H430V-448h100q27 0 46.83-19.83 19.84-19.84 19.84-46.84v-100.66q0-27-19.84-46.84Q557-682 530-682H363.33v66.67H530v100.66H430q-27 0-46.83 19.84Q363.33-475 363.33-448v170Z"/></svg>}
          position={<><span>قم بربط بيانات متجرك بمنصتنا لتحليل الأداء. نحن نضمن أمانًا على مستوى البنك، ونحتاج فقط إلى وصول للقراءة فقط دون تغيير بياناتك.</span>
          </>} />
          <MemberComponent
          name="اختر العرض و استلم التمويل" name1= "48 ساعة" 
          img={<svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#1F366B"><path d="M480.18-80q-82.83 0-155.67-31.5-72.84-31.5-127.18-85.83Q143-251.67 111.5-324.56T80-480.33q0-82.88 31.5-155.78Q143-709 197.33-763q54.34-54 127.23-85.5T480.33-880q82.88 0 155.78 31.5Q709-817 763-763t85.5 127Q880-563 880-480.18q0 82.83-31.5 155.67Q817-251.67 763-197.46q-54 54.21-127 85.84Q563-80 480.18-80Zm.15-66.67q139 0 236-97.33t97-236.33q0-139-96.87-236-96.88-97-236.46-97-138.67 0-236 96.87-97.33 96.88-97.33 236.46 0 138.67 97.33 236 97.33 97.33 236.33 97.33ZM480-480ZM363.33-278H530q27 0 46.83-19.83 19.84-19.84 19.84-46.84v-78q0-26.66-15.34-42.66-15.33-16-37.33-16 22 0 37.33-14.67 15.34-14.67 15.34-42v-77.33q0-27-19.84-46.84Q557-682 530-682H363.33v66.67H530v100.66h-84.67V-448H530v103.33H363.33V-278Z"/></svg>}
          position={<><span>قاختر خيار التمويل الذي يناسب احتياجاتك. بعد الموافقة، يمكننا إرسال الأموال إلى حسابك البنكي في غضون 24 ساعة.</span>
          </>} />

      </Container>
      <Link style={{marginTop : "32px", marginBottom : "52px"}} to={"/finance-application"} onClick={() => { }}><Button text="قدم الان" ></Button></Link>
    </Section>
  );
};

export default ProductFinanceAppPitch;
