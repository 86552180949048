import React from "react";
import styled from "styled-components";

import img1 from "../../assets/greate_ux.png";
import img2 from "../../assets/secure.png";
import img3 from "../../assets/simple.png";
import img4 from "../../assets/value.png";
import { FormattedMessage } from "react-intl";
import { Context } from "../Wrapper";
import { useIntl } from "react-intl";

const Section = styled.section`
  /* min-height: 100vh; */
  /* min-height: 110vh; */
  width: 100vw;

    /* background-color: ${(props) => props.theme.body}; */
  position: relative;

  direction: ${(props) => props.direction};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "IBM Plex Sans Arabic", sans-serif;

`;

const Title = styled.h1`
  /* font-size: ${(props) => props.theme.fontxxl}; */
  text-transform: capitalize;
  font-size: xx-large;
  /* color: ${(props) => props.theme.text}; */
  /* align-self: flex-start; */
  /* display: flex; */
  /* justify-content: right; */
  /* align-items: left; */
  text-align: center;
  /* margin: 1rem auto; */
  /* border-bottom: 2px solid ${(props) => props.theme.text}; */
  /* width: fit-content; */
  /* width: 80%; */
  /* color: #002856; */
  width: ${(props) => props.theme.width};
  padding-top: 20px;
  padding-bottom: 20px;

  color: #1F366B;

  font-family: "IBM Plex Sans Arabic", sans-serif;


  @media (max-width: ${(props) => props.theme.width}) {
    font-size: xx-large;
    width: 90%;
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.text};
  /* margin: 1rem auto; */
  font-weight: 400;
  /* align-items: center; */
  /* align-self: center; */
  /* align-self: flex-start; */
  /* text-align: center; */



  @media (max-width: ${(props) => props.theme.width}) {
    width: 90%;
    font-size: ${(props) => props.theme.fontmd};
  }
`;

const Container = styled.div`
  width: 85%;
  margin: 2rem auto;
  width: 1160px;

  display: flex;
  /* justify-content: space-between; */
  justify-content: space-between;
  /* align-content: center; */
  flex-wrap: wrap;
  gap: 24px;
  @media (max-width: 64em) {
    width: 85%;
  }
  @media (max-width: 48em) {
    width: 90%;
    justify-content: center;
  }
  margin-bottom: 8em;
`;

const Item = styled.div`
  width: calc(20rem - 4vw);
  /* padding: 24px 24px; */
  padding-top: 24px;
  color: ${(props) => props.theme.body};
  /* margin: 1rem 2rem; */
  position: relative;

  /* border: 2px solid ${(props) => props.theme.text}; */
  border-radius: 20px;
  transition: all 0.3s ease;
  flex-grow: 1;

  &:hover {
    img {
      transform: translateY(-2rem) scale(1.2);
    }
  }

  @media (max-width: 30em) {
    width: 70vw;
  }
`;

const ImageContainer = styled.div`
  /* width: 60%; */
  /* margin: 0 auto; */
  /* background-color: ${(props) => props.theme.carouselColor}; */
  /* background-color: #f5f5f5; */

  /* border: 1px solid ${(props) => props.theme.text}; */
  /* padding: 1rem; */

  /* border-radius: 20px; */
  /* cursor: pointer; */

  /* img {
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
  } */
`;

const Name = styled.h3`
  /* font-size: ${(props) => props.theme.fontsm}; */
  display: flex;
  /* align-items: center; */
  /* justify-content: left; */
  /* text-transform: uppercase; */
  color: ${(props) => props.theme.text};
  margin-top: 1rem;
  /* text-align: -webkit-center; */
  min-height: 40px;
  justify-content: center;
  font-size: 30px;
  margin-top: 28px;
  color: #1F366B;

  font-family: "IBM Plex Sans Arabic", sans-serif;


`;

const Position = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: rgba(${(props) => props.theme.textRgba}, 0.9);
  font-weight: 400;
  padding-top: 1rem;
  justify-content: center;
  text-align: -webkit-center;
  line-height: normal;
  font-size: 20px;

  font-family: "IBM Plex Sans Arabic", sans-serif;


`;

const Icon = styled.i`
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 20px;  /* Preferred icon size */
  /* display: inline-block; */
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ${(props) => props.direction};
  vertical-align: top;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  /* font-feature-settings: 'liga'; */
  /* margin-right: 10px;
  margin-left: 10px; */

  display: flex;
    flex-direction: column;
    align-items: center;


`;

const MemberComponent = ({ img, name = " ", position = " " }) => {
  return (
    <Item>
      {img}
      <Name>{name}</Name>
      <Position>{position}</Position>
    </Item>
  );
};

const ProductFinanceAppFeatures = () => {
  const context = React.useContext(Context);

  const intl = useIntl();
  // const title1 = intl.formatMessage({ id: "app.features.greateFinEx" });
  const title1 = "موافقة سريعة";
  // const details1 = intl.formatMessage({
  //   id: "app.features.greateFinExDetails",
  // });
  const details1 = "نطلع على بياناتك الرقمية ليتم تقديم عروض تمويلية خلال 48 ساعة";



  // const title2 = intl.formatMessage({ id: "app.features.extreamSec" });
  const title2 = "متطلبات بسيطة";
  // const details2 = intl.formatMessage({
  //   id: "app.features.extreamSecDetails",
  // });
  const details2 = "لا حاجة لكشوفات مالية، حيث يتم تقييم الجدارة الائتمانية بناءً على أداء مبيعاتك";

  const title3 = intl.formatMessage({ id: "app.features.extreamSimple" });
  const details3 = intl.formatMessage({
    id: "app.features.extreamSimpleDetails",
  });

  // const title4 = intl.formatMessage({ id: "app.features.value" });
  const title4 = "تمويل على حسب الحاجة";
  // const details4 = intl.formatMessage({
  //   id: "app.features.valueDetails",
  // });
  const details4 = "  من 10,000 إلى 20 مليون جنيه مصري متاحة لتمويل نموك";

  return (
    <Section
      id="team"
      direction={context.locale.startsWith("ar") ? "rtl" : "ltr"}
    >
      <Title>لماذا ليبرو؟</Title>
      <Container>
        <MemberComponent img={<Icon ><svg style={{background: "#D3E1FB", padding: "8px", borderRadius: "8px"}} xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#0693E3"><path d="m200-553.67 96.67 41Q313.33-546 332-578q18.67-32 40-62l-71.33-14.33L200-553.67ZM350-472l126.67 126.33q52-22.66 101.33-55.66T662-469q77.33-77.33 115.83-162.5T816.67-812q-95.34.33-180.67 38.83-85.33 38.5-162.67 115.84-34.66 34.66-67.66 84Q372.67-524 350-472Zm212-85.67q-21-21-21-51.83t21-51.83q21-21 52-21t52 21q21 21 21 51.83t-21 51.83q-21 21-52 21t-52-21Zm-3.67 362.34L659-296l-14.33-71.33q-30 21.33-62 39.83t-65.34 35.17l41 97ZM880-875.67q12.33 131-30.5 243.84Q806.67-519 706-418.33q-.67.66-1.33 1.33-.67.67-1.34 1.33l21.34 106.34Q728-292.67 723-277q-5 15.67-17 27.67L536-78.67l-84.67-197.66L281-446.67 83.33-531.33l170.34-170q12-12 27.83-17 15.83-5 32.5-1.67l106.33 21.33q.67-.66 1.34-1 .66-.33 1.33-1 100.67-100.66 213.33-144Q749-888 880-875.67Zm-728.33 552q35-35 85.5-35.5t85.5 34.5q35 35 34.5 85.5t-35.5 85.5q-25.67 25.67-81.5 43-55.84 17.34-162.84 32Q92-185.67 109-241.83q17-56.17 42.67-81.84Zm47 47.34Q186-263 175.33-232.83q-10.66 30.16-17.33 72.5 42.33-6.67 72.5-17.17 30.17-10.5 43.5-23.17 16.67-15.33 17.33-38Q292-261.33 276-278q-16.67-16-39.33-15.5-22.67.5-38 17.17Z"/></svg></Icon>} name={title1} position={details1} />
        <MemberComponent img={<Icon><svg style={{background: "#D3E1FB", padding: "8px", borderRadius: "8px"}} xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#0693E3"><path d="M160-160v-100q0-36.67 18.5-64.17T226.67-366q65.33-30.33 127.66-45.5 62.34-15.17 125.67-15.17 25 0 50 2.5t50 7.5v66.34q-25.33-5.34-50-7.5-24.67-2.17-50-2.17-56.67 0-110.67 12.5T254.67-306q-12.34 6.33-20.17 19-7.83 12.67-7.83 27v33.33H580V-160H160Zm66.67-66.67H580 226.67Zm253.33-254q-66 0-109.67-43.66Q326.67-568 326.67-634t43.66-109.67Q414-787.33 480-787.33t109.67 43.66Q633.33-700 633.33-634t-43.66 109.67Q546-480.67 480-480.67Zm0-66.66q37 0 61.83-24.84Q566.67-597 566.67-634t-24.84-61.83Q517-720.67 480-720.67t-61.83 24.84Q393.33-671 393.33-634t24.84 61.83Q443-547.33 480-547.33Zm0-86.67ZM726.67-25.33v-181.34h-80v-226.66h222.66l-80 160h79.34l-142 248Z"/></svg></Icon>} name={title2} position={details2} />
        <MemberComponent img={<Icon><svg style={{background: "#D3E1FB", padding: "8px", borderRadius: "8px"}} xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#0693E3"><path d="M546.67-426.67q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM240-293.33q-27.5 0-47.08-19.59-19.59-19.58-19.59-47.08v-373.33q0-27.5 19.59-47.09Q212.5-800 240-800h613.33q27.5 0 47.09 19.58Q920-760.83 920-733.33V-360q0 27.5-19.58 47.08-19.59 19.59-47.09 19.59H240ZM333.33-360H760q0-39 27.17-66.17 27.16-27.16 66.16-27.16V-640q-39 0-66.16-27.17Q760-694.33 760-733.33H333.33q0 39-27.16 66.16Q279-640 240-640v186.67q39 0 66.17 27.16Q333.33-399 333.33-360ZM800-160H106.67q-27.5 0-47.09-19.58Q40-199.17 40-226.67V-680h66.67v453.33H800V-160ZM240-360v-373.33V-360Z"/></svg></Icon>} name={title4} position={details4} />

        {/* <MemberComponent img={img4} name={title4} position={details4} /> */}
      </Container>
    </Section>
  );
};

export default ProductFinanceAppFeatures;
